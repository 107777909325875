import { Component, createComponent } from '../metadata/component';
import { Query } from '../metadata/di';
import { Reflector } from '../reflector';


const QUERY_METADATA_IDENTIFIERS = [
	'ComponentRef',
	'ComponentRefs',
	'ChildRef',
	'ChildrenRef'
];

// todo: @opten/resolver? or @opten/initializer/resolver?
export class Resolver {
	constructor(private reflector: Reflector) {}

	// propertyCache = new Map<StaticSymbol, { [key: string]: any[] }>();

	resolve(type: any /*Type*/): Component | null {
		const typeMetadata = this.reflector.annotations(type);

		if (typeMetadata) {
			const metadata = findLast(
				typeMetadata,
				c => c && c.metadataName === 'Component'
			);

			if (metadata) {
				const propertyMetadata = this.reflector.propMetadata(type);

				return this._mergeWithPropertyMetadata(
					metadata,
					propertyMetadata,
					type
				);
			}
		}

		return null;
	}

	private _mergeWithPropertyMetadata(
		component: Component,
		propertyMetadata: { [key: string]: any[] },
		componentType: /*Type*/ any
	): Component {
		const queries: { [key: string]: any } = {};

		Object.keys(propertyMetadata).forEach((propName: string) => {
			const query = findLast(propertyMetadata[propName], a =>
				QUERY_METADATA_IDENTIFIERS.some(i => i && i === a.metadataName)
			);

			if (query) {
				queries[propName] = query;
			}
		});

		return this._merge(component, queries, componentType);
	}

	private _merge(
		component: Component,
		queries: { [key: string]: any },
		componentType: /*Type*/ any
	): Component {
		const mergedQueries = component.queries
			? { ...component.queries, ...queries }
			: queries;

		return createComponent({
			className: component.className,
			observe: component.observe,
			queries: mergedQueries
		});
	}

	private _getQueriesMetadata(queries: { [key: string]: Query }): any[] {
		const res: any[] = [];

		Object.keys(queries).forEach((propertyName: string) => {
			const query = queries[propertyName];
			res.push(this._getQueryMetadata(query, propertyName));
		});

		return res;
	}

	private _getQueryMetadata(q: Query, propertyName: string): any {
		return {
			descendants: q.descendants,
			first: q.first,
			propertyName,
			read: false,
			selector: q.selector
		};
	}
}

function findLast<T>(arr: T[], condition: (value: T) => boolean): T | null {
	for (let i = arr.length - 1; i >= 0; i--) {
		if (condition(arr[i])) {
			return arr[i];
		}
	}
	return null;
}
