import { removeDomain } from "../../helper-url/remove-domain";

export function getScriptBySrc(src: string): HTMLScriptElement {
	// HINT: NEVER EVER change to Array.from() we need this code in load-polyfill
	// and IE doesn't understand this until the polyfills are obviously loaded!
	const scripts = document.getElementsByTagName('script');
	for (let i = 0; i < scripts.length; i++) {
		const currentScriptElement = scripts.item(i);
		const currentSrc = removeDomain(currentScriptElement.src);
		if (currentSrc === src) {
			return currentScriptElement;
		}
	}
	return null;
}
