import { Component } from "../../Core/lib-compiler/metadata/component";
import { BluetoothComponent } from "./bluetooth";

@Component({ className: 'js-data' })
export class DataComponent {
	private ble: BluetoothComponent;

	private liveBtn: HTMLButtonElement;
	private dataVehicleSpeed: HTMLElement;
	private dataEngineSpeed: HTMLElement;
	private dataEngineOil: HTMLElement;
	private dataEngineCoolant: HTMLElement;

	private dataIntervals: number[] = [];

	constructor(private element: HTMLElement) {
		this.liveBtn = element.querySelector('.js-ble-live') as HTMLButtonElement;
		this.dataVehicleSpeed = element.querySelector('.js-data-vehicle-speed') as HTMLElement;
		this.dataEngineSpeed = element.querySelector('.js-data-engine-speed') as HTMLElement;
		this.dataEngineOil = element.querySelector('.js-data-engine-oil') as HTMLElement;
		this.dataEngineCoolant = element.querySelector('.js-data-engine-coolant') as HTMLElement;

		document.addEventListener('ble.init', (e: CustomEvent) => {
			this.ble = e.detail;
			this.liveBtn.disabled = false;
		});

		this.liveBtn.addEventListener('click', (e) => {
			e.preventDefault();
			if (this.dataIntervals.length > 0) {
				this.dataIntervals.forEach(interval => {
					clearInterval(interval);
				});
				this.dataIntervals = [];
			} else {
				this.getData();
			}
		});
	}

	private getData() {
		this.dataIntervals.push(setInterval(async () => {
			const vehicleSpeed = await this.ble.getPid('010D');
			const engineSpeed = await this.ble.getPid('010C');
			const engineCoolant = await this.ble.getPid('0105');
			const engineOil = await this.ble.getPid('2101');
			//const gear = await this.ble.getPid('01A4'); //7F0112
			this.dataVehicleSpeed.innerHTML = vehicleSpeed.a.toString();
			this.dataEngineSpeed.innerHTML = ((256 * engineSpeed.a + engineSpeed.b) / 4).toString();
			this.dataEngineCoolant.innerHTML = (engineCoolant.a - 40).toString();
			this.dataEngineOil.innerHTML = (engineOil.a - 40).toString();
		}, 1000));
	}
}
