import { Type } from './type';

export const ANNOTATIONS = '__annotations__';
export const PROP_METADATA = '__prop__metadata__';

export interface TypeDecorator {
	// Invoke as ES7 decorator.
	<T extends Type<any>>(type: T): T;

	// Make TypeDecorator assignable to built-in ParameterDecorator type.
	// ParameterDecorator is declared in lib.d.ts as a `declare type`
	// so we cannot declare this interface as a subtype.
	// see https://github.com/angular/angular/issues/3379#issuecomment-126169417
	(
		target: object,
		propertyKey?: string | symbol,
		parameterIndex?: number
	): void;
}

export function makeDecorator(
	name: string,
	props?: (...args: any[]) => any,
	parentClass?: any,
	chainFn?: (fn: TypeDecorator) => void,
	typeFn?: (type: Type<any>, ...args: any[]) => void
): {
	new (...args: any[]): any;
	(...args: any[]): any;
	(...args: any[]): (cls: any) => any;
} {
	const metaCtor = makeMetadataCtor(props);

	function DecoratorFactory(...args: any[]): (cls: any) => any {
		if (this instanceof DecoratorFactory) {
			metaCtor.call(this, ...args);
			return this;
		}

		const annotationInstance = new (DecoratorFactory as any)(...args);
		const typeDecorator: TypeDecorator = (cls: Type<any>) => {
			if (typeFn) {
				typeFn(cls, ...args);
			}
			// Use of Object.defineProperty is important since it creates non-enumerable property which
			// prevents the property is copied during subclassing.
			const annotations = cls.hasOwnProperty(ANNOTATIONS)
				? (cls as any)[ANNOTATIONS]
				: Object.defineProperty(cls, ANNOTATIONS, { value: [] })[
						ANNOTATIONS
				  ];
			annotations.push(annotationInstance);
			return cls;
		};
		if (chainFn) {
			chainFn(typeDecorator);
		}
		return typeDecorator;
	}

	if (parentClass) {
		DecoratorFactory.prototype = Object.create(parentClass.prototype);
	}

	DecoratorFactory.prototype.metadataName = name;
	return DecoratorFactory as any;
}

function makeMetadataCtor(props?: (...args: any[]) => any): any {
	return function ctor(...args: any[]) {
		if (props) {
			const values = props(...args);
			for (const propName in values) {
				if (values.hasOwnProperty(propName)) {
					this[propName] = values[propName];
				}
			}
		}
	};
}

export function makePropDecorator(
	name: string,
	props?: (...args: any[]) => any,
	parentClass?: any
): any {
	const metaCtor = makeMetadataCtor(props);

	function PropDecoratorFactory(...args: any[]): any {
		if (this instanceof PropDecoratorFactory) {
			metaCtor.apply(this, args);
			return this;
		}

		const decoratorInstance = new (PropDecoratorFactory as any)(...args);

		return function PropDecorator(target: any, metaName: string) {
			const constructor = target.constructor;
			// Use of Object.defineProperty is important since it creates non-enumerable property which
			// prevents the property is copied during subclassing.
			const meta = constructor.hasOwnProperty(PROP_METADATA)
				? (constructor as any)[PROP_METADATA]
				: Object.defineProperty(constructor, PROP_METADATA, {
						value: {}
				  })[PROP_METADATA];
			meta[metaName] =
				(meta.hasOwnProperty(metaName) && meta[metaName]) || [];
			meta[metaName].unshift(decoratorInstance);
		};
	}

	if (parentClass) {
		PropDecoratorFactory.prototype = Object.create(parentClass.prototype);
	}

	PropDecoratorFactory.prototype.metadataName = name;
	return PropDecoratorFactory;
}
