import { createScript } from '../create-script';
import { getScriptBySrc } from '../get-script-by-src';

interface PolyfillWindow extends Window {
	polyfillsLoadedFlag: boolean;
	polyfillsLoadedCallbacks: Function[];
	polyfillsLoaded: () => void;
}

const polyfillWindow = window as PolyfillWindow;

const polyfillesLoadedFn = () => {
	if (polyfillWindow.polyfillsLoadedCallbacks.length) {
		// tslint:disable-next-line:prefer-for-of
		for (
			let i = 0;
			i < polyfillWindow.polyfillsLoadedCallbacks.length;
			i++
		) {
			polyfillWindow.polyfillsLoadedCallbacks[i]();
		}
	}
	polyfillWindow.polyfillsLoadedFlag = true;
};

polyfillWindow.polyfillsLoadedFlag = false;
polyfillWindow.polyfillsLoadedCallbacks =
	polyfillWindow.polyfillsLoadedCallbacks || [];
polyfillWindow.polyfillsLoaded =
	polyfillWindow.polyfillsLoaded || polyfillesLoadedFn;

export function loadPolyfillv2(
	cb: () => void,
	features: string[] = ['es6', 'CustomEvent', 'fetch', 'HTMLPictureElement']
) {
	return loadPolyfill(
		cb,
		`https://cdn.polyfill.io/v2/polyfill.min.js?features=${features.join(
			','
		)}&flags=gated&callback=polyfillsLoaded`
	);
}

export function loadPolyfill(
	cb: () => void,
	// tslint:disable-next-line:max-line-length
	src = 'https://cdn.polyfill.io/v2/polyfill.min.js?features=es6,CustomEvent,fetch,HTMLPictureElement&flags=gated&callback=polyfillsLoaded'
) {
	polyfillWindow.polyfillsLoadedCallbacks.push(cb);

	const script = getScriptBySrc(src);

	if (script) {
		if (polyfillWindow.polyfillsLoadedFlag) {
			cb();
		}
	} else {
		document.head.appendChild(createScript(src, cb));
	}
}
