import { makeDecorator, TypeDecorator } from '../util/decorators';
import { makeMetadataFactory, MetadataFactory } from '../util/factories';

export interface ComponentDecorator {
	(obj: Component): TypeDecorator;
	new (obj: Component): Component;
}

export interface Component {
	className?: string;
	observe?: boolean;
	queries?: { [key: string]: any };
}

// tslint:disable-next-line:variable-name
export const Component: ComponentDecorator = makeDecorator(
	'Component',
	(c: Component = {}) => ({ ...c })
);

export const createComponent: MetadataFactory<Component> = makeMetadataFactory<
	Component
>('Component', (c: Component = {}) => ({ ...c }));
