import { ANNOTATIONS, PROP_METADATA } from '../util/decorators';
import { isType, Type } from '../util/type';


// todo: @opten/reflector? or @opten/initializer/reflector?
export class Reflector {
	annotations(typeOrFunc: Type<any>): any[] {
		if (!isType(typeOrFunc)) {
			return [];
		}
		const parentCtor = getParentCtor(typeOrFunc);
		const ownAnnotations =
			this._ownAnnotations(typeOrFunc, parentCtor) || [];
		const parentAnnotations =
			parentCtor !== Object ? this.annotations(parentCtor) : [];
		return parentAnnotations.concat(ownAnnotations);
	}

	propMetadata(typeOrFunc: any): { [key: string]: any[] } {
		if (!isType(typeOrFunc)) {
			return {};
		}
		const parentCtor = getParentCtor(typeOrFunc);
		const propMetadata: { [key: string]: any[] } = {};
		if (parentCtor !== Object) {
			const parentPropMetadata = this.propMetadata(parentCtor);
			Object.keys(parentPropMetadata).forEach(propName => {
				propMetadata[propName] = parentPropMetadata[propName];
			});
		}
		const ownPropMetadata = this._ownPropMetadata(typeOrFunc, parentCtor);
		if (ownPropMetadata) {
			Object.keys(ownPropMetadata).forEach(propName => {
				const decorators: any[] = [];
				if (propMetadata.hasOwnProperty(propName)) {
					decorators.push(...propMetadata[propName]);
				}
				decorators.push(...ownPropMetadata[propName]);
				propMetadata[propName] = decorators;
			});
		}
		return propMetadata;
	}

	private _ownAnnotations(
		typeOrFunc: Type<any>,
		parentCtor: any
	): any[] | null {
		// API for metadata created by invoking the decorators.
		if (typeOrFunc.hasOwnProperty(ANNOTATIONS)) {
			return (typeOrFunc as any)[ANNOTATIONS];
		}
		return null;
	}

	private _ownPropMetadata(
		typeOrFunc: any,
		parentCtor: any
	): { [key: string]: any[] } | null {
		// API for metadata created by invoking the decorators.
		if (typeOrFunc.hasOwnProperty(PROP_METADATA)) {
			return (typeOrFunc as any)[PROP_METADATA];
		}
		return null;
	}
}

function getParentCtor(ctor: Type<any>): Type<any> {
	const parentProto = Object.getPrototypeOf(ctor.prototype);
	const parentCtor = parentProto ? parentProto.constructor : null;
	// Note: We always use `Object` as the null value
	// to simplify checking later on.
	return parentCtor || Object;
}
