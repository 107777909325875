import { Component } from "../../Core/lib-compiler/metadata/component";

@Component({ className: 'js-ble' })
export class BluetoothComponent {

	private connectBtn: HTMLButtonElement;
	private disconnectBtn: HTMLButtonElement;
	private sendBtn: HTMLButtonElement;
	private commandField: HTMLInputElement;
	private logField: HTMLInputElement;

	private UartGattServiceId = 0xfff0;
	private UartGattCharacteristicReceiveId = 0xfff1;
	private UartGattCharacteristicSendId = 0xfff2;
	private SpecialNotificationDescriptorId = 0x2902;

	private device: any;
	private server: any;
	private service: any;
	private sendCharacteristic: any;
	private receiveCharacteristic: any;

	private encoder: TextEncoder;
	private decoder: TextDecoder;

	private receiveValue: string;

	constructor(private element: HTMLElement) {
		this.connectBtn = element.querySelector('.js-ble-connect') as HTMLButtonElement;
		this.disconnectBtn = element.querySelector('.js-ble-disconnect') as HTMLButtonElement;
		this.sendBtn = element.querySelector('.js-ble-send') as HTMLButtonElement;
		this.commandField = element.querySelector('.js-ble-command') as HTMLInputElement;
		this.logField = element.querySelector('.js-ble-log') as HTMLInputElement;

		this.encoder = new TextEncoder();
		this.decoder = new TextDecoder();

		this.connectBtn.addEventListener('click', (e) => {
			e.preventDefault();
			this.logField.value = '';
			this.init().then((success) => {
				if (success) {
					this.connectBtn.disabled = true;
					this.disconnectBtn.disabled = false;
					this.sendBtn.disabled = false;
					const event = new CustomEvent('ble.init', { detail: this });
					document.dispatchEvent(event);
				}
			});
		});

		this.disconnectBtn.addEventListener('click', (e) => {
			e.preventDefault();
			this.device.gatt.disconnect();
			this.connectBtn.disabled = false;
			this.disconnectBtn.disabled = true;
			this.sendBtn.disabled = true;
		});

		this.sendBtn.addEventListener('click', (e) => {
			e.preventDefault();
			this.sendCmd(this.commandField.value).then((success) => {
				if (success) {
					this.commandField.value = '';
				}
			});
		});
	}

	public async getPid(pid: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.sendCmd(pid).then((value: string) => {
				if (value.startsWith("41")) {
					const hex = value.substr(4);
					const a = parseInt(hex.substr(0, 2), 16);
					const b = parseInt(hex.substr(2, 4), 16);
					const c = parseInt(hex.substr(4, 6), 16);
					const d = parseInt(hex.substr(6, 8), 16);
					const res = { a, b, c, d };
					this.log(JSON.stringify(res));
					resolve(res);
				} else if (value.indexOf('4:') != -1) {
					const hex = value.substr((value.indexOf('4:') + 2) + 6);
					const a = parseInt(hex.substr(0, 2), 16);
					const b = parseInt(hex.substr(2, 4), 16);
					const c = parseInt(hex.substr(4, 6), 16);
					const d = parseInt(hex.substr(6, 8), 16);
					const res = { a, b, c, d };
					this.log(JSON.stringify(res));
					resolve(res);
				} else {
					resolve(value);
				}
			});
		});
	}

	public async sendCmd(cmd: string) {
		this.receiveValue = '';
		return new Promise((resolve, reject) => {
			this.sendCharacteristic.writeValue(this.encoder.encode(cmd + '\r\n'));
			document.addEventListener('ble.value.changed', (data: CustomEvent) => {
				resolve(data.detail);
			})
		});
	}

	private async init() {
		try {
			this.device = await navigator.bluetooth.requestDevice({
				//acceptAllDevices: true,
				filters: [{ services: [this.UartGattServiceId] }],
			});
			this.log('device: ' + this.device.name);

			this.server = await this.device.gatt.connect();
			this.device.addEventListener('gattserverdisconnected', () => this.log('Bluetooth Device disconnected'));
			this.log('server: ' + this.server);

			this.service = await this.server.getPrimaryService(this.UartGattServiceId);
			this.log('services: ' + this.service);

			this.sendCharacteristic = await this.service.getCharacteristic(this.UartGattCharacteristicSendId);

			this.receiveCharacteristic = await this.service.getCharacteristic(this.UartGattCharacteristicReceiveId);
			this.receiveCharacteristic.addEventListener('characteristicvaluechanged', (e) => this.handleNotifications(e));

			await this.receiveCharacteristic.startNotifications();

			// init AT cmds
			await this.sendCmd("ATD");
			await this.sendCmd("ATZ");
			await this.sendCmd("ATS0");
			await this.sendCmd("ATE0");
			await this.sendCmd("ATL0");
			await this.sendCmd("ATSH7E0");
			await this.sendCmd("ATH0");
			await this.sendCmd("ATSP0");
			await this.sendCmd("ATSS");

			await this.getPid("0100");
			return true;
		} catch (error) {
			this.log('error: ' + error.message);
			console.error(error);
			return false;
		}
	}

	private handleNotifications(event) {
		const valueDecoded = this.decoder.decode(event.target.value);
		this.receiveValue += valueDecoded;
		this.log('valueDecoded: ' + valueDecoded);
		if (valueDecoded.indexOf('>') != -1) {
			const event = new CustomEvent('ble.value.changed', { detail: this.receiveValue });
			document.dispatchEvent(event);
		}
	}

	private log(message: string) {
		this.logField.value += message + '\n' + '\n';
		this.logField.scrollTop = this.logField.scrollHeight;
		console.log(message);
	}
}
