import { Component } from './metadata/component';
import { Query } from './metadata/di';
import { OnInit } from './metadata/lifecycle';
import { Resolver } from './resolver';
import { isType } from './util/type';


// todo: move whole project to @opten/initializer?
export class Compiler {
	private componentCache = new Map<HTMLElement, /*Type*/ any[]>();

	constructor(private resolver: Resolver) {}

	compile(
		component: Component,
		node: HTMLElement,
		componentType: /*Type*/ any
	) {
		// todo: cache everything?

		// todo: cache that also?
		const constructor = new componentType(node);

		if (this.componentCache.has(node)) {
			this.componentCache.get(node).push(constructor);
		} else {
			this.componentCache.set(node, [constructor]);
		}

		Object.keys(component.queries).forEach(propName => {
			const query: Query = component.queries[propName];

			// todo: why do we have to inject the resolver?
			// can we not get the class name by some other way?
			const target = this.resolver.resolve(query.selector);

			if (target) {
				const targetNode = query.descendants ? node : document;
				const nodes = targetNode.getElementsByClassName(
					target.className
				);

				const cache = Array.from(nodes)
					.filter(o => (query.filter ? query.filter(o) : true))
					.map(e => this.componentCache.get(e as HTMLElement))
					.reduce((acc, val) => acc.concat(val), [])
					.filter(f => f.constructor === query.selector);

				constructor[propName] = query.first ? cache[0] : cache;
			}
		});

		if (isType(constructor.onInit)) {
			(constructor as OnInit).onInit();
		}
	}
}

