

export interface MetadataFactory<T> {
	(...args: any[]): T;
	metadataName: string;
	isTypeOf(obj: any): obj is T;
}

export function makeMetadataFactory<T>(
	name: string,
	props?: (...args: any[]) => T
): MetadataFactory<T> {
	const factory: any = (...args: any[]) => {
		const values = props ? props(...args) : {};
		return {
			metadataName: name,
			...values
		};
	};
	factory.isTypeOf = (obj: any) => obj && obj.metadataName === name;
	factory.metadataName = name;
	return factory;
}
