import { loadPolyfillv2 } from '../Core/helper-script/load-polyfill';
import { factory } from '../Core/lib-compiler/factory';
import { BluetoothComponent } from './components/bluetooth';
import { DataComponent } from './components/data';
import './main.scss';



loadPolyfillv2(
	() => {
		factory([
			BluetoothComponent,
			DataComponent
		])(document.body);
	}
);
