interface LoadingScriptWindow extends Window {
	loadingScripts: string[];
}

const loadingScriptWindow = window as LoadingScriptWindow;

loadingScriptWindow.loadingScripts = loadingScriptWindow.loadingScripts || [];

export function createScript(src: string, onerror: () => void = () => {}) {
	const scriptElement = document.createElement('script');
	scriptElement.src = src;
	scriptElement.async = false;
	scriptElement.onload = e => {
		removeLoadingScript(src);
	};
	scriptElement.onerror = e => {
		console.error(e);
		removeLoadingScript(src);
		onerror();
	};
	loadingScriptWindow.loadingScripts.push(src);
	return scriptElement;
}

function removeLoadingScript(src: string) {
	const index = loadingScriptWindow.loadingScripts.indexOf(src);
	if (index > -1) {
		loadingScriptWindow.loadingScripts = loadingScriptWindow.loadingScripts.splice(
			index,
			1
		);
	}
}
