import { asHTMLElement } from '../../helper-dom/as-html-element';
import { isHTMLElement } from '../../helper-dom/is-html-element';
import { hasClass } from '../../helper-dom/has-class';

import { Compiler } from '../';
import { Component } from '../metadata/component';
import { Reflector } from '../reflector';
import { Resolver } from '../resolver';

// todo: cache, static, inject?
const reflector = new Reflector();
const resolver = new Resolver(reflector);
const compiler = new Compiler(resolver);

export function factory(components: any[]) {
	return (rootElement: HTMLElement) => {
		const observables = new Map<Component, any>();
		components.forEach(componentType => {
			// todo: inject resolver, or cache?
			// todo: static or something to cache?
			const component = resolver.resolve(componentType);

			if (component) {
				Array.from(
					document.getElementsByClassName(component.className)
				).forEach(node =>
					compiler.compile(
						component,
						node as HTMLElement,
						componentType
					)
				);

				if (component.observe) {
					observables.set(component, componentType);
				}
			} else {
				throw new Error(
					`No @Component annotation for ${
						componentType ? componentType.name : componentType
					} found!`
				);
			}
		});

		if (observables.size) {
			new MutationObserver(mutations => {
				const componentInitializer = new Map<HTMLElement, () => void>();

				mutations.forEach(mutation => {
					observables.forEach((value, key) =>
						Array.from(mutation.addedNodes)
							.filter(node => isHTMLElement(node))
							.map(node => asHTMLElement(node))
							.forEach(node => {
								const comps = Array.from(
									node.getElementsByClassName(key.className)
								);
								if (hasClass(node, key.className)) {
									comps.push(node);
								}
								comps.forEach(component => {
									componentInitializer.set(
										component as HTMLElement,
										() =>
											compiler.compile(
												key,
												component as HTMLElement,
												value
											)
									);
								});
							})
					);
				});

				componentInitializer.forEach(c => c());
			}).observe(rootElement, {
				childList: true,
				subtree: true
			});
		}
	};
}
